import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { S25ProgressBarComponent } from "./s25.progress.bar.component";

@NgModule({
    imports: [CommonModule],
    declarations: [S25ProgressBarComponent],
    exports: [S25ProgressBarComponent],
    providers: [S25ProgressBarComponent],
})
export class S25ProgressBarModule {}
