import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { S25Const } from "../../util/s25-const";
import { Publisher, PublisherService } from "../../services/publisher.service";
import { Item } from "../../pojo/Item";
import { S25ModalComponent } from "../s25-modal/s25.modal.component";
import SimpleHistoryItem = Publisher.SimpleHistoryItem;
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-ng-publish-search")
@Component({
    selector: "s25-ng-publish-search",
    template: `
        @if (isInit) {
            <div class="body">
                @if (!!history?.length) {
                    <div class="status">
                        <label class="ngBold">This search is already being sent to</label>
                        @if (isRefreshingHistory) {
                            <s25-ng-loading-inline-static></s25-ng-loading-inline-static>
                        }
                        @if (!isRefreshingHistory) {
                            <div class="pills">
                                @for (item of history; track item) {
                                    <s25-ng-pill (remove)="alterHistory(item)">
                                        {{ item.name }}
                                    </s25-ng-pill>
                                }
                            </div>
                        }
                    </div>
                }
                <div class="type">
                    <label class="ngBold">Type</label>
                    <s25-ng-radio [(modelValue)]="type" [value]="'send'" name="publishSearchType" class="ngBlock">
                        Send {{ readableFeedType }} "{{ searchName }}" to an existing 25Live Publisher calendar
                    </s25-ng-radio>
                    <s25-ng-radio [(modelValue)]="type" [value]="'create'" name="publishSearchType" class="ngBlock">
                        Create a new 25Live Publisher calendar for these events
                    </s25-ng-radio>
                </div>
                <div>
                    <label class="ngBold">{{ type === "send" ? "Send To Calendar" : "Create Calendar" }}</label>
                    @if (type === "send") {
                        <select class="cn-form__control ngBlock" [(ngModel)]="sendToCalendar">
                            @for (calendar of calendars; track calendar) {
                                <option [ngValue]="calendar" [disabled]="historyIds.has($any(calendar.id))">
                                    {{ calendar.dispName }}
                                </option>
                            }
                        </select>
                    }
                    @if (type === "create") {
                        <div>
                            <input
                                type="text"
                                id="publishSearchCalendarName"
                                [(ngModel)]="newCalendarName"
                                class="c-input ngBlock"
                                placeholder="Calendar Name"
                            />
                            <s25-ng-checkbox [(modelValue)]="createAsSubCalendar" class="ngBlock">
                                Sub-calendar of:
                            </s25-ng-checkbox>
                            <select
                                class="cn-form__control ngBlock"
                                [(ngModel)]="subCalendarOf"
                                [disabled]="!createAsSubCalendar"
                            >
                                @for (calendar of calendars; track calendar) {
                                    <option [ngValue]="calendar">
                                        {{ calendar.dispName }}
                                    </option>
                                }
                            </select>
                        </div>
                    }
                </div>
                <div class="options">
                    <label class="ngBold">Options</label>
                    <s25-ng-checkbox class="ngBlock" [(modelValue)]="prefs.useEventTitle">
                        Show event title (rather than event name)
                    </s25-ng-checkbox>
                    <s25-ng-checkbox class="ngBlock" [(modelValue)]="prefs.rsrvService">
                        Publish only event occurrences with location assignments
                    </s25-ng-checkbox>
                    <s25-ng-checkbox class="ngBlock" [(modelValue)]="prefs.spaceFormalName">
                        Show location formal name (rather than short name)
                    </s25-ng-checkbox>
                    <s25-ng-checkbox class="ngBlock" [(modelValue)]="prefs.reservationComments">
                        Include reservation comments
                    </s25-ng-checkbox>
                    <s25-ng-checkbox class="ngBlock" [(modelValue)]="prefs.setupTakedown">
                        Include setup and takedown times
                    </s25-ng-checkbox>
                    <s25-ng-checkbox class="ngBlock" [(modelValue)]="prefs.includeCancelled">
                        Include cancelled
                    </s25-ng-checkbox>
                </div>
                <div>
                    @if (isPublishing) {
                        <s25-ng-loading-inline-static [text]="''"></s25-ng-loading-inline-static>
                    }
                    <button class="aw-button aw-button--primary" (click)="publish()" [disabled]="isPublishing">
                        {{ isPublishing ? "Publishing..." : "Publish" }}
                    </button>
                </div>
            </div>
        }
        <div class="footer">
            <div class="signOut">
                signed in as <em class="ngBold fontStyleNormal">{{ username }}</em> (<a (click)="signOut()">sign out</a
                >)
            </div>
            <div class="openPublisher" title="Open 25Live Publisher in new window">
                <a [href]="href" target="_blank" rel="noopener"> Open <span class="ngBold">25Live Publisher</span> </a>
            </div>
        </div>

        <s25-ng-modal #confirmModal [type]="'deny'" [title]="'Confirm'" [size]="'sm'">
            {{ confirmMessage }}
        </s25-ng-modal>
    `,
    styles: `
        .body {
            display: flex;
            flex-direction: column;
            gap: 1em;
            margin-bottom: 2em;
        }

        .pills {
            display: flex;
            gap: 0.5em;
            flex-wrap: wrap;
        }

        .footer {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        .signOut a:hover {
            text-decoration: underline;
        }

        .fontStyleNormal {
            font-style: normal;
        }
    `,
})
export class S25PublishSearchComponent implements OnInit {
    @Input() itemType: Item.Id;
    @Input() itemId: number;
    @Input() searchQuery: string;
    @Input() searchName: string;
    @Input() feedType: Publisher.FeedType;

    @ViewChild("confirmModal") confirmModal: S25ModalComponent;

    isInit = false;
    username: string;
    href = S25Const.publisher_signin;
    history: Publisher.SimpleHistoryItem[];
    historyIds: Set<number>;
    confirmMessage: string;
    isRefreshingHistory = false;
    readableFeedType: string;
    type: "send" | "create" = "create";
    calendars: Publisher.CalendarListItem[];
    sendToCalendar: Publisher.CalendarListItem;
    createAsSubCalendar: boolean = false;
    subCalendarOf: Publisher.CalendarListItem;
    newCalendarName: string;
    prefs: Publisher.Preferences & { includeCancelled: boolean };
    isPublishing = false;

    @Output() loggedOut = new EventEmitter<void>();

    async ngOnInit() {
        this.username = PublisherService.getUsername();
        this.getFeedType();

        await Promise.all([this.getHistory(), this.getCalendars(), this.getPrefs()]);
        this.newCalendarName = this.searchName;

        this.isInit = true;
    }

    async signOut() {
        PublisherService.logout();
        this.loggedOut.emit();
    }

    async getHistory() {
        this.isRefreshingHistory = true;
        const itemTypeName = S25Const.itemId2Name[this.itemType];
        const data = await PublisherService.getHistory(itemTypeName, this.searchQuery, this.feedType, undefined);
        this.history = PublisherService.getHistoryList(data);
        this.historyIds = new Set((this.history || []).map((item) => Number(item.id)));
        this.isRefreshingHistory = false;
    }

    async getCalendars() {
        this.calendars = await PublisherService.getCalendarList();
        this.sendToCalendar = this.calendars[0];
        this.subCalendarOf = this.calendars[0];
    }

    async getPrefs() {
        const prefs = await PublisherService.getPublisherPreferencesPure(this.itemType);
        this.prefs = { ...prefs, includeCancelled: false };
    }

    async alterHistory(item: SimpleHistoryItem) {
        this.confirmMessage = `Stop sending events from "${this.searchName}" to the "${item.name}" calendar?`;
        if (!(await this.confirmModal.open())) return;

        this.isRefreshingHistory = true;
        await PublisherService.removeFeed(item.uid);
        await this.getHistory();
    }

    getFeedType() {
        if (this.feedType === "r25_object") {
            if (this.itemType === Item.Ids.Event) this.readableFeedType = "reservations for event";
            else if (this.itemType === Item.Ids.Location) this.readableFeedType = "events in location";
        } else if (this.feedType === "r25_group") {
            if (this.itemType === Item.Ids.Event) this.readableFeedType = "reservations in event search";
            else if (this.itemType === Item.Ids.Location) this.readableFeedType = "events in location search";
        }
    }

    async publish() {
        this.isPublishing = true;

        const createCalId = this.createAsSubCalendar ? this.subCalendarOf.id : undefined;
        const calendarId = this.type === "send" ? this.sendToCalendar.id : createCalId;
        const calendarName = this.type === "send" ? this.sendToCalendar.name : this.newCalendarName;
        const itemId =
            this.feedType === "r25_group" ? PublisherService.publisherBean.cachedSearchQueryHash : this.itemId;

        const method = this.type === "send" ? PublisherService.sendToCalendarDao : PublisherService.createCalendarDao;
        await method(
            calendarId,
            calendarName,
            this.feedType,
            S25Const.itemId2Name[this.itemType],
            PublisherService.publisherBean.cachedUsername,
            PublisherService.publisherBean.cachedPassword,
            this.prefs.useEventTitle,
            this.prefs.spaceFormalName,
            this.prefs.reservationComments,
            this.prefs.setupTakedown,
            this.prefs.rsrvService,
            this.prefs.chunkSize,
            this.prefs.pastWindow,
            this.prefs.futureWindow,
            itemId,
            this.searchName,
            PublisherService.publisherBean.cachedSearchQuery,
            this.prefs.includeCancelled,
        );

        await Promise.all([this.getHistory(), this.type === "create" && this.getCalendars()]);

        this.isPublishing = false;
    }
}
