import { Component, EventEmitter, Output } from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { PublisherService } from "../../services/publisher.service";

@TypeManagerDecorator("s25-ng-publisher-login")
@Component({
    selector: "s25-ng-publisher-login",
    template: `
        <div class="flexColumn loginWrapper" (keydown.enter)="signIn()">
            <p class="ngBold textCenter">Sign In using your 25Live Publisher credentials</p>
            <label for="publisherUsername" class="ngBlock">25Live Publisher Username</label>

            <div class="textCenter">
                <input class="c-input" type="text" id="publisherUsername" [(ngModel)]="username" />
            </div>
            <label for="publisherPassword" class="ngBlock">25Live Publisher Password</label>
            <div class="textCenter">
                <input class="c-input" type="password" id="publisherPassword" [(ngModel)]="password" />
            </div>
            <div class="textCenter">
                @if (isSigningIn) {
                    <s25-ng-loading-inline-static [text]="''"></s25-ng-loading-inline-static>
                }
                <button class="aw-button aw-button--primary" (click)="signIn()" [disabled]="isSigningIn">
                    {{ isSigningIn ? "Signing In..." : "Sign In" }}
                </button>
            </div>
        </div>
    `,
    styles: `
        .flexColumn {
            display: flex;
            flex-direction: column;
        }

        .loginWrapper {
            max-width: 30em;
            margin: auto;
            padding: 1em;
        }

        label,
        button {
            margin-top: 1em;
        }

        .textCenter {
            text-align: center;
        }
    `,
})
export class S25PublisherLoginComponent {
    username: string;
    password: string;

    isSigningIn = false;

    @Output() loggedIn = new EventEmitter<void>();

    async signIn() {
        const cookie = S25Util.getCookie("publisherUsername");
        if (!cookie && (!this.username || !this.password)) return;
        this.isSigningIn = true;
        const loggedIn = await PublisherService.login(this.username, this.password).catch(console.error);
        this.isSigningIn = false;
        if (!loggedIn) return;
        this.loggedIn.emit();
    }
}
