import { NgModule } from "@angular/core";
import { S25PublisherLoginComponent } from "./s25.publisher.login.component";
import { S25PublishSearchModalComponent } from "./s25.publish.search.modal.component";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";
import { S25IconModule } from "../s25-icon/s25.icon.module";
import { S25PillModule } from "../s25-pill/s25.pill.module";
import { S25PublishSearchComponent } from "./s25.publish.search.component";
import { S25ModalModule } from "../s25-modal/s25.modal.module";
import { S25RadioModule } from "../s25-radio/s25.radio.module";
import { S25ToggleButtonModule } from "../s25-toggle-button/s25.toggle.button.module";
import { S25CheckboxModule } from "../s25-checkbox/s25.checkbox.module";

@NgModule({
    declarations: [S25PublisherLoginComponent, S25PublishSearchModalComponent, S25PublishSearchComponent],
    imports: [
        CommonModule,
        FormsModule,
        S25LoadingInlineModule,
        S25IconModule,
        S25PillModule,
        S25ModalModule,
        S25RadioModule,
        S25ToggleButtonModule,
        S25CheckboxModule,
    ],
    exports: [S25PublisherLoginComponent, S25PublishSearchModalComponent, S25PublishSearchComponent],
    providers: [S25PublisherLoginComponent, S25PublishSearchModalComponent, S25PublishSearchComponent],
})
export class S25PublisherModule {}
