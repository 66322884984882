import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-ng-progress-bar")
@Component({
    selector: "s25-ng-progress-bar",
    template: `@if (init) {
        <div class="progress-bar--wrapper">
            @for (level of levels; track level; let i = $index) {
                <div
                    class="progress-level"
                    [ngClass]="{
                        'active-node': currentLevel === level && !complete,
                        complete: complete || i < levels.indexOf(currentLevel),
                    }"
                >
                    <div class="progress-node">
                        @if (complete || i < levels.indexOf(currentLevel)) {
                            <svg class="c-svgIcon progress-check" role="img">
                                <title>Completed</title>
                                <use
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#check"
                                ></use>
                            </svg>
                        }
                        @if (!complete && currentLevel === level) {
                            <svg class="c-svgIcon progress-caret" role="img">
                                <title>Active</title>
                                <use
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#caret--caret-down"
                                ></use>
                            </svg>
                        }
                        @if (!complete && i >= levels.indexOf(currentLevel)) {
                            <div class="progress-level-indicator">
                                {{ i + 1 }}
                            </div>
                        }
                    </div>
                    @if (i < levels.length - 1) {
                        <div class="progress-line"></div>
                    }
                    <div class="progress-label">{{ level }}</div>
                </div>
            }
        </div>
    }`,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25ProgressBarComponent implements OnInit {
    @Input() levels: string[];
    @Input() currentLevel: string;
    @Input() complete: boolean;

    init: boolean;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private elementRef: ElementRef,
    ) {
        elementRef.nativeElement.angBridge = this;
    }

    ngOnInit() {
        this.init = true;
    }
}
