import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { Table } from "../s25-table/Table";
import { Bind } from "../../decorators/bind.decorator";
import { ListService } from "../../services/list.service";
import { OptService } from "../../services/opt.service";
import { OptBean, OptColumnChooser, ViewButtonValue, S25OptComponent } from "../s25-opt/s25.opt.component";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { Proto } from "../../pojo/Proto";
import { S25TableComponent } from "../s25-table/s25.table.component";
import { PreferenceService } from "../../services/preference.service";
import { S25FavoriteSimpleComponent } from "../s25-favorite/s25.favorite.simple.component";
import { S25ItemGenericComponent } from "../s25-item/item-object/s25.item.generic.component";
import { GenericTableListComponent } from "../s25-table/generics/generic.table.list.component";
import { S25Datefilter } from "../s25-dateformat/s25.datefilter.service";
import { UserprefService } from "../../services/userpref.service";
import { Item } from "../../pojo/Item";
import { S25Const } from "../../util/s25-const";
import { TaskService } from "../../services/task/task.service";
import { S25TaskEditComponent } from "../s25-task/s25.task.edit.component";
import { S25TaskActionComponent } from "../s25-task/s25.task.action.component";
import { TableEditableDateComponent } from "../s25-table/generics/table-editable-date.component";
import { ContactService } from "../../services/contact.service";
import { Task } from "../../pojo/Task";
import { S25TaskStatusComponent } from "../s25-task/s25.task.status.component";
import ISODateString = Proto.ISODateString;
import { TaskTiersService } from "../../services/task/task.tiers.service";

const stickyColumnPreference = {
    [Item.Ids.Event]: "25L_event_search_columns",
    [Item.Ids.Location]: "25L_space_search_columns",
    [Item.Ids.Resource]: "25L_resource_search_columns",
    [Item.Ids.Organization]: "25L_org_search_columns",
    [Item.Ids.Task]: "25L_task_overview_columns",
    [Item.Ids.Contact]: "25L_cont_search_columns",
} as any;

@TypeManagerDecorator("s25-ng-search-results-list")
@Component({
    selector: "s25-ng-search-results-list",
    template: `
        @if (optInit && isInit) {
            <s25-ng-opt
                [modelBean]="optBean"
                [hasListTab]="true"
                [hasCalendarTab]="type !== 3"
                [hasAvailDailyTab]="type === 4 || type === 6"
                [hasAvailWeeklyTab]="type === 4"
                [selectedTab]="'list'"
                [hasDatepicker]="type === 1"
                [hasVariableDatepicker]="true"
                [hasDaysSelector]="true"
                [hasDateViews]="type === 1"
                [selectedDateView]="dateView"
                [columnChooser]="columnChooser"
                [hasCreateTodo]="type === 10"
                [hasRefresh]="true"
                [help]="'default'"
                [hasBulkEdit]="true"
                (tabChange)="onTabChange($event)"
                (dateChange)="onDateChange($event)"
                (endDateChange)="onEndDateChange($event)"
                (dateViewChange)="onDateViewChange($event)"
                (refreshed)="onRefresh()"
                (columnChooserChange)="onColumnChooserChange($event)"
            ></s25-ng-opt>
        }
        @if (isInit) {
            <s25-ng-table
                [columnSortable]="true"
                [dataSource]="tableData"
                [stickyColumnSortPreference]="S25Const.itemId2Name[type]"
                [unlimitedWidth]="true"
                [pivotThreshold]="0"
                [hasTotalRowCount]="true"
                [minColWidth]="50"
            ></s25-ng-table>
        }
    `,
    styles: `
        ::ng-deep s25-ng-search-results-list tbody tr td input[type="checkbox"] {
            height: 16px !important;
        }

        ::ng-deep s25-ng-search-results-list s25-item-generic {
            color: rgb(37, 115, 167);
        }

        ::ng-deep s25-ng-search-results-list s25-item-generic s25-popover > .ngInlineBlock {
            display: block !important;
        }

        ::ng-deep s25-ng-search-results-list .s25-ng-table--cell.stateTentative {
            font-style: italic;
        }

        ::ng-deep s25-ng-search-results-list .s25-ng-table--cell.stateCancelled {
            color: #d62000;
            font-weight: 400;
        }

        ::ng-deep .nm-party--on s25-ng-search-results-list .s25-ng-table--cell.stateCancelled {
            color: #ff837a;
        }
        ::ng-deep s25-ng-search-results-list s25-ng-table .italic {
            font-style: italic;
        }

        ::ng-deep s25-ng-search-results-list s25-ng-task-edit > span {
            display: block !important;
        }

        ::ng-deep s25-ng-search-results-list s25-ng-table s25-ng-editable-date .editable-input {
            width: 100% !important;
        }

        ::ng-deep s25-ng-search-results-list s25-ng-table thead th.s25TableColumn {
            color: #000000cc !important;
            font-size: 14px;
            padding: 1em 0.5em !important;
        }

        ::ng-deep .nm-party--on s25-ng-search-results-list s25-ng-table thead th.s25TableColumn {
            color: #fff !important;
        }

        ::ng-deep s25-ng-search-results-list s25-ng-table .tableWrapper {
            border: 1px solid #e5e5e5;
        }

        ::ng-deep s25-ng-search-results-list s25-ng-table tbody tr:not(:last-of-type) {
            border-bottom: 1px solid #e5e5e5;
        }

        ::ng-deep s25-ng-search-results-list s25-ng-table .s25-ng-table--header:not(:first-child),
        ::ng-deep s25-ng-search-results-list s25-ng-table .s25-ng-table--cell:not(:first-child) {
            border-left: 1px solid #e5e5e5;
        }

        ::ng-deep .nm-party--on s25-ng-search-results-list s25-ng-table .tableWrapper {
            border: 1px solid rgba(255, 255, 255, 0.24);
        }

        ::ng-deep .nm-party--on s25-ng-search-results-list s25-ng-table tbody tr:not(:last-of-type) {
            border-bottom: 1px solid rgba(255, 255, 255, 0.24);
        }

        ::ng-deep .nm-party--on s25-ng-search-results-list s25-ng-table .s25-ng-table--header:not(:first-child),
        ::ng-deep .nm-party--on s25-ng-search-results-list s25-ng-table .s25-ng-table--cell:not(:first-child) {
            border-left: 1px solid rgba(255, 255, 255, 0.24);
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25SearchResultsListComponent implements OnInit {
    @Input() type: Item.Id;
    @Input() query: string;
    @Input() searchModel?: any; // bulk edit button in opt
    @Input() tabChangeCallback: (tab: "availabilityWeekly" | "list" | "availability" | "calendar") => void; // ONLY FOR INTERFACE WITH JS COMPONENTS. USE @Output FOR TS COMPONENTS
    @Input() searchResultsCallback: (data: any) => void; // ONLY FOR INTERFACE WITH JS COMPONENTS. USE @Output FOR TS COMPONENTS
    @Input() dateViewCallback: (view: ViewButtonValue) => void; // ONLY FOR INTERFACE WITH JS COMPONENTS. USE @Output FOR TS COMPONENTS
    @Output() tabChange = new EventEmitter<"availabilityWeekly" | "list" | "availability" | "calendar">();

    @ViewChild(S25TableComponent) tableComponent: S25TableComponent;
    @ViewChild(S25OptComponent) optComponent: S25OptComponent;

    isInit = false;
    optInit = false;
    optBean: any = { hasOpt: true }; // tried use "OptBean" type get error, use "any" instead;
    tableData: Table.Paginated = {
        type: "paginated",
        dataSource: this.getRows,
        columns: [],
        defaultPageSize: 25,
        pageSizes: [25, 50, 100],
        customRowCount: this.getRowCount,
    };
    columnChooser: OptColumnChooser;
    startDate: ISODateString;
    endDate: ISODateString;
    dateView: ViewButtonValue;
    dateFormat: string;
    timeFormat: string;
    dateTimeFormat: string;
    paginationCacheId: number;
    queryGenerator: () => Promise<{ query: string; postSearch?: () => void }>;
    S25Const = S25Const;
    data: any;
    userId: number;
    workflowChained: boolean;
    sortColumn: string;
    sortOrder: "asc" | "desc";

    constructor(
        private changeDetector: ChangeDetectorRef,
        private elementRef: ElementRef,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    async ngOnInit() {
        const [optData, prefs, dateFormat, timeFormat, dateTimeFormat, userId, workflowPref] = await Promise.all([
            OptService.getS25Opt("list", S25Const.itemId2Name[this.type]),
            PreferenceService.getGenericPreference("list", [
                `sticky_sort_col_${S25Const.itemId2Name[this.type]}`,
                `sticky_sort_col_dir_${S25Const.itemId2Name[this.type]}`,
                `sticky_page_size_${S25Const.itemId2Name[this.type]}`,
            ]),
            UserprefService.getS25Dateformat(),
            UserprefService.getS25Timeformat(),
            UserprefService.getS25DateTimeformat(),
            ContactService.getCurrentId(),
            TaskTiersService.isWorkflowChained(),
        ]);
        this.dateView = optData.pref.dates_option || "recentHistory";
        this.dateViewCallback?.(this.dateView); // Make sure parent has correct initial value
        this.tableData.defaultPageSize = prefs[`sticky_page_size_${S25Const.itemId2Name[this.type]}`];
        this.dateFormat = dateFormat;
        this.timeFormat = timeFormat;
        this.dateTimeFormat = dateTimeFormat;
        this.userId = userId;
        this.workflowChained = workflowPref;
        this.optBean.searchQuery = this.query;
        this.optBean.objectId = this.type;
        this.optBean.searchModel = this.searchModel;
        this.isInit = true;
        this.changeDetector.detectChanges();
    }

    @Bind
    async getRows(query: Table.PaginatedQuery): Promise<Table.DataSourceResponse> {
        this.searchResultsCallback(null); // Reset when search is running
        const data = (await this.getData(query)) || [];
        this.searchResultsCallback(data); // Set when we have results
        this.optBean.totalSearchRowCount = data?.rows?.[0]?.count || data?.rows?.length;
        this.optBean.searchCacheId = data?.cacheId;

        // Get data first then init opt bar
        if (this.optInit) {
            this.optComponent.ngOnInit();
        } else {
            this.optInit = true;
            this.changeDetector.detectChanges();
        }

        return {
            rows: data.rows.map((row: any) => this.mapToRow(data.cols, row)),
            totalRows: data.rows?.[0]?.count,
        };
    }

    async getData(tableQuery: Table.PaginatedQuery) {
        if (tableQuery.forceRefresh) this.paginationCacheId = undefined; // If refresh is forced, don't reuse cache
        if (tableQuery.sortColumn.id !== this.sortColumn || tableQuery.sortColumn.order !== this.sortOrder) {
            // If sorting changed, reset pagination
            this.paginationCacheId = undefined;
            this.tableComponent.currentPage = 0;
            tableQuery.page = 0;
        }
        this.sortColumn = tableQuery.sortColumn.id;
        this.sortOrder = tableQuery.sortColumn.order;

        const { query, postSearch } = (await this.queryGenerator?.()) ?? { query: this.query };

        if (this.type === Item.Ids.Task) {
            const taskCount = this.data?.taskCount;
            this.data = await ListService.getTaskData({
                query,
                page: tableQuery.page + 1,
                pageSize: tableQuery.pageSize,
                sortColumn: tableQuery.sortColumn.id,
                sortOrder: tableQuery.sortColumn.order,
                cacheId: this.paginationCacheId,
            });
            if (!this.data.taskCount) this.data.taskCount = taskCount;
        } else {
            this.data = await ListService.getData2({
                itemType: S25Const.itemId2Name[this.type],
                dateOption: this.dateView,
                startDate: !this.dateView && this.startDate,
                endDate: !this.dateView && this.endDate,
                page: tableQuery.page + 1,
                itemsPerPage: tableQuery.pageSize,
                sort: { column: tableQuery.sortColumn.id, order: tableQuery.sortColumn.order },
                query,
                cacheId: this.paginationCacheId,
            });
        }
        this.paginationCacheId = this.data.cacheId;
        postSearch?.();

        // Update columns in table
        const columns = this.data.cols.map((column: any) => {
            const data: Table.Column = {
                header: column.name,
                id: column.prefname,
                sortable: !!column.sortable,
            };
            switch (column.prefname) {
                case "event_name":
                case "name": // Location name
                case "event": // Task event
                case "last_name": // Contact
                    data.content = { component: S25ItemGenericComponent };
                    break;
                case "locations":
                case "resources":
                case "organization":
                    data.content = { component: GenericTableListComponent };
                    break;
                case "task_item":
                    data.content = { component: S25TaskEditComponent };
                    break;
                case "status":
                    data.content = { component: S25TaskStatusComponent };
                    break;
                case "actions":
                    data.content = { component: S25TaskActionComponent };
                    break;
                case "respond_by":
                    data.content = { component: TableEditableDateComponent };
                    data.minWidth = 80;
                    break;
                case "first_date":
                case "start_date":
                    data.minWidth = 60;
                    break;
                case "creation_date":
                    data.minWidth = 100;
                    break;
                case "instructor":
                    data.minWidth = 40;
                    break;
                default:
                    if (column.name === "IsFav") {
                        data.id = "isFav";
                        data.header = "";
                        data.width = "min-content";
                        data.content = { component: S25FavoriteSimpleComponent };
                    }
            }
            return data;
        });
        this.tableComponent.setColumns(columns);

        // Update columns in column chooser
        this.columnChooser = {
            prefName: stickyColumnPreference[this.type],
            columns: this.data.cols.map((column: any) => {
                if (!column.prefname) {
                    if (column.name === "IsFav")
                        return { id: "isFav", name: "Favorite", checked: true, permanent: true };
                }
                return { id: column.prefname, name: column.name, checked: !!column.isVisible };
            }),
        };
        this.onColumnChooserChange(this.columnChooser);
        this.changeDetector.detectChanges();
        return this.data;
    }

    @Bind
    mapToRow(columns: any[], row: any): Table.Row {
        const cells: Table.Row["cells"] = {};
        for (let i = 0; i < columns.length; i++) {
            const [key, val] = this.getCell(columns[i], row.row[i]);
            cells[key] = val;
        }

        return {
            id: row.contextId,
            name: this.type === Item.Ids.Task ? row.row[0].itemName : row.row[1].itemName,
            cells,
        };
    }

    getCell(column: any, data: any): [string, Table.Cell] {
        let modelBean: any;
        switch (column.prefname) {
            case "event_name":
            case "name": // Location name
            case "last_name": // Contact
                modelBean = { itemTypeId: data.itemTypeId, itemId: data.itemId, itemName: data.itemName };
                return [column.prefname, { inputs: { modelBean } }];
            case "event": // Task event
                if (!data.itemName)
                    return [column.prefname, { component: undefined, text: "none", className: "italic" }];
                modelBean = { itemTypeId: data.itemTypeId, itemId: data.itemId, itemName: data.itemName };
                return [column.prefname, { inputs: { modelBean } }];
            case "bldg_name":
                return [column.prefname, { text: data.itemName }];
            case "locations":
            case "resources":
            case "organization":
                const items = data.subject?.map((sub: any) => ({
                    component: S25ItemGenericComponent,
                    inputs: { modelBean: { itemTypeId: sub.itemTypeId, itemId: sub.itemId, itemName: sub.itemName } },
                }));
                return [column.prefname, { inputs: { items: items || [], hasDecoration: false } }];
            case "start_date":
            case "first_date": // Task first date
                return [column.prefname, { text: S25Datefilter.transform(data, this.dateFormat) }];
            case "start_time":
                return [column.prefname, { text: S25Datefilter.transform(data, this.timeFormat) }];
            case "creation_date":
                return [column.prefname, { text: S25Datefilter.transform(data, this.dateTimeFormat) }];
            case "state_name":
                return [column.prefname, { text: data, className: `state${data}` }];
            case "task_item":
                const in1 = { showIcon: true, taskBlocked: this.workflowChained && data.taskBlocked, taskModel: data };
                const out1 = { onRefresh: () => this.tableComponent.refresh(true) };
                return [column.prefname, { inputs: in1, outputs: out1 }];
            case "status": // Task status
                return [column.prefname, { inputs: { task: data } }];
            case "actions":
                return [column.prefname, { inputs: data, outputs: { stateChange: this.onTaskAction } }];
            case "respond_by":
                const in2 = { val: data.respond_by, readOnly: data.assigned_to_id !== this.userId };
                const out2 = {
                    valChange: (val: Date, row: Table.Row, instance: TableEditableDateComponent) => {
                        const isTodo = parseInt(data.task_type) === 5 || parseInt(data.task_type) === 6;
                        if (isTodo) TaskService.putTodoDueDate(data.task_id, val);
                        else TaskService.putEventTaskDueDate(data.event_id, data.task_id, val);
                    },
                };
                return [column.prefname, { inputs: in2, outputs: out2 }];
            default:
                if (typeof data === "object" && "isFav" in data) {
                    return [
                        "isFav",
                        {
                            inputs: {
                                starred: !!data.isFav,
                                itemId: data.itemId,
                                itemType: data.itemTypeId,
                                loggedIn: true,
                            },
                        },
                    ];
                }
                return [column.prefname, { text: data }];
        }
    }

    onTabChange(tab: "availabilityWeekly" | "list" | "availability" | "calendar") {
        this.tabChangeCallback?.(tab);
        this.tabChange.emit(tab);
    }

    onDateChange(date: Date) {
        if (!date) return;
        this.startDate = S25Util.date.toS25ISODateTimeStr(S25Util.date.toStartOfDay(date));
        this.dateView = undefined;
    }

    onEndDateChange(date: Date) {
        if (!date) return;
        if (this.endDate) this.dateView = undefined; // Do not update view on first change
        this.endDate = S25Util.date.toS25ISODateTimeStr(S25Util.date.toEndOfDay(date));
        this.tableComponent.currentPage = 0; // Reset pagination on date change
    }

    onRefresh() {
        return this.tableComponent.refresh(true);
    }

    onDateViewChange(view: ViewButtonValue) {
        this.dateView = view;
        this.tableComponent.currentPage = 0; // Reset pagination on view change
        this.dateViewCallback(view);
    }

    onQueryChange(query: string) {
        this.optBean.searchModel = undefined; // for bulk edit
        this.optBean.searchQuery = query;
        this.query = query;
        this.queryGenerator = null;
        return this.tableComponent.refresh(true, true);
    }

    onQueryGeneratorChange(generator: () => Promise<{ query: string; postSearch?: () => void }>) {
        this.queryGenerator = generator;
        return this.tableComponent.refresh(true, true);
    }

    onModelChange(model: any) {
        this.optBean.searchModel = model;
    }

    onColumnChooserChange(columnChooser: OptColumnChooser) {
        const visible = new Set(columnChooser.columns.filter((column) => column.checked).map((column) => column.id));
        this.tableComponent.setVisibleColumns(visible);
    }

    @Bind
    onTaskAction(state: Task.State, row: Table.Row) {
        // Update task item column
        const taskItem = row.cells.task_item.instance as S25TaskEditComponent;
        if (taskItem) {
            taskItem.taskModel.itemStateId = state;
            taskItem.refresh();
        }
        // Update status column
        const status = row.cells.status.instance as S25TaskStatusComponent;
        if (status) {
            status.task.task_state = state;
            status.refresh();
        }
    }

    @Bind
    getRowCount(counts: Table.RowCounts) {
        const type = S25Util.firstCharToUpper(S25Const.itemId2Name[this.type]) + (counts.total === 1 ? "" : "s");
        if (this.type === Item.Ids.Task && this.data.taskCount !== counts.total) {
            const taskItems = `Task Item${counts.total === 1 ? "" : "s"}`;
            return `${counts.total} Matching ${taskItems} with ${this.data.taskCount} Tasks`;
        }
        return `${counts.total} Matching ${type}`;
    }
}
